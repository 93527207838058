import { logIn, sendOTP } from '@/api/auth';
import { resetStudentData } from '@/store/reducers/profile';
import { removeToken } from '@/utils/auth';
import { resetUserData, setUserRegistered, userGetAndStore } from '..';

export const sendLoginOTP = (data) => async () => {
	return await sendOTP(data);
};

export const loginUser =
	(loginDetails, fetchUser = true) =>
	async (dispatch) => {
		const result = await logIn(loginDetails);
		if (result.success) {
			dispatch(setUserRegistered(true));
			fetchUser && dispatch(userGetAndStore());
		}
		return result;
	};

export const logoutUser = () => {
	return (dispatch) => {
		removeToken();
		dispatch(resetUserData());
		dispatch(resetStudentData());
	};
};
