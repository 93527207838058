// ALL REDUCERS
import cmsGlobalData, { getCMSGlobalData } from '../reducers/cmsGlobalData';
import common from '../reducers/common';
import { getExitIntentModalPropsAction } from '../reducers/exitIntentModal';
import filter from '../reducers/filter';
import footer, { getFooterPropsAction } from '../reducers/footer';
import global, { getGlobalPropsAction } from '../reducers/global';
import menuBar, { getMenuBarPropsAction } from '../reducers/menuBar';
import profile from '../reducers/profile';
import user, { userGetAndStore } from '../reducers/user';
import userModal, {
	sendOTPAndOpenUserModal,
	setResendOTPTime,
} from '../reducers/userModal';
// Actions
export * from './auth';
export {
	goToExternalRoute,
	goToRoute,
	isExternalUrl,
	setQueries,
	setQueriesWithOptions,
	setQuery,
} from './navigation';
export {
	getCMSGlobalData,
	getExitIntentModalPropsAction,
	getFooterPropsAction,
	getGlobalPropsAction,
	getMenuBarPropsAction,
	sendOTPAndOpenUserModal,
	setResendOTPTime,
	userGetAndStore,
};
export const {
	getMenuBarProps,
	getMenuBarPropsSuccess,
	getMenuBarPropsFailure,
} = menuBar.actions;
export const { getFooterProps, getFooterPropsSuccess, getFooterPropsFailure } =
	footer.actions;
export const { getGlobalProps, getGlobalPropsSuccess, getGlobalPropsFailure } =
	global.actions;
export const {
	setDevice,
	setIsEventPage,
	setPaymentState,
	setDynamicZoneLoadedSectionIndex,
	setDynamicZoneSectionCount,
} = common.actions;
export const {
	setUserModalLoading,
	setUserModalError,
	setUserModalData,
	resetUserModalData,
} = userModal.actions;
export const { setUserRegistered, setUserData, resetUserData } = user.actions;
export const {
	getCMSGlobalProps,
	getCMSGlobalPropsSuccess,
	getCMSGlobalPropsFailure,
} = cmsGlobalData.actions;

export const {
	setFilterData,
	setFormFilterData,
	setFilterText,
	setFilterTag,
	setFilterToggle,
	resetFilterData,
} = filter.actions;

export const { resetStudentData, setStudentData, setStudentLoading } =
	profile.actions;
