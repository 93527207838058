import constants from '@/constants';
import { getPersistentData } from './browserStorage/persistentData';

export default function parseJwt(token) {
	if (!token) {
		return;
	}
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
}

export function getStudentInfoFromToken(token) {
	const accessToken =
		token || getPersistentData(constants.cookie.authAccessToken);

	if (!accessToken) return null;

	const infoFromToken = parseJwt(accessToken);
	return { accessToken, id: infoFromToken.sub, ...infoFromToken.payload };
}
