import { getStudentInfoFromToken } from '@/utils/tokenParser';

let createAnalytics = function () {
	let modules = [];
	let registerModule = function (module) {
		modules.push(module);
	};

	let init = function () {
		modules.forEach((m) => {
			m.init();
		});
	};

	let eventsFunctions = {
		trackEvent: function (
			eventName,
			eventProperties = {},
			exclude = '',
			addionalProperties
		) {
			if (typeof window !== 'undefined') {
				if (process.env.APP_ENV === 'development') {
					console.log('Event Fired: ', eventName, eventProperties);
				}

				const userID = (getStudentInfoFromToken() || {}).id || 'PUBLIC';
				const properties = {
					userID,
					eventCategory: '',
					eventLabel: '',
					section: '',
					subSection: '',
					eventAction: '',
					azent_pathname: window.location.pathname,
					...eventProperties,
				};
				modules.forEach((m) => {
					if (!exclude.includes(m.name) && m.trackEvent) {
						m.trackEvent(eventName, properties, addionalProperties);
					}
				});
			}
		},
		identify: function (data, exclude = '') {
			if (typeof window !== 'undefined') {
				if (process.env.APP_ENV === 'development') {
					console.log('Identify Event Fired: ', data);
				}
				modules.forEach((m) => {
					if (!exclude.includes(m.name) && m.identify) {
						m.identify(data);
					}
				});
			}
		},
		logPageView: function (exclude = '') {
			if (typeof window !== 'undefined') {
				if (process.env.APP_ENV === 'development') {
					console.log('Page View Event Fired: ', window.location.pathname);
				}
				modules.forEach((m) => {
					if (!exclude.includes(m.name) && m.logPageView) {
						m.logPageView();
					}
				});
			}
		},
	};

	return {
		registerModule,
		init,
		eventsFunctions,
	};
};

export default createAnalytics;
