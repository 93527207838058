import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import startCase from 'lodash/startCase';
import words from 'lodash/words';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import constants from '@/constants';

// utils
import { ID } from './uniqueID';
const defaultTitle =
	constants.platform === 'partner'
		? 'AdmissionBox'
		: 'Azent Overseas Education';

export const pageTitle = (pageData) => {
	if (!pageData) return defaultTitle;

	let title =
		get(pageData, "meta['og:title'].content") || get(pageData, 'title');

	if (!title) {
		if (!pageData.slug || pageData.slug === 'home-page') title = defaultTitle;
		else title = startCase(words(pageData.slug).join(' '));
	}

	return title;
};

export default function Meta(props) {
	const pathname = useSelector((state) => state.router.location.pathname);
	const currentURL = constants.seo.sitemapBaseUrl + pathname;

	const metaTagList = [],
		metaTags = props.metaTags,
		uniqueKey = ID();

	if (metaTags) {
		for (const key in metaTags) {
			if (isPlainObject(metaTags[key])) {
				metaTagList.push(<meta {...metaTags[key]} key={key} />);
			}
		}

		if (metaTags['og:title']) {
			metaTagList.push(
				<title key={uniqueKey + 1}>{metaTags['og:title'].content}</title>
			);
		}

		if (metaTags['og:description'] && !metaTags['description']) {
			metaTagList.push(
				<meta
					name="description"
					content={metaTags['og:description'].content}
					key={uniqueKey + 2}
				/>
			);
		}

		if (!metaTags['og:type']) {
			metaTagList.push(
				<meta property="og:type" content="website" key={uniqueKey + 3} />
			);
		}

		if (!metaTags['og:url']) {
			metaTagList.push(
				<meta property="og:url" content={currentURL} key={uniqueKey + 4} />
			);
		}
	}

	return (
		<Head>
			{metaTagList}
			{constants.seo.index && props.noIndex && (
				<meta name="robots" content="noindex, nofollow" />
			)}
		</Head>
	);
}

Meta.propTypes = {
	metaTags: PropTypes.object,
};

Meta.defaultProps = {
	noIndex: false,
};
