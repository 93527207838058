import constants from '@/constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// constants
import {
	appHighlightSectionConfig,
	blogListingSectionConfig,
	compassCtaSectionConfig,
	compassExpenseCalculatorCtaSectionConfig,
	compassExploreCourseCtaSectionConfig,
	contactAndLocationSectionConfig,
	defaultCoverImage,
	detailBannerSectionConfig,
	examAcceptedTableConfig,
	generalInformationDataKeys,
	generalInformationSectionConfig,
	getCostTableConfig,
	getFeeEstimateTableConfig,
	indexBannerSectionConfig,
	modalFormCtaSectionConfig,
	navigationSectionConfig,
	overviewSectionConfig,
	partnerCommissionCtaSectionConfig,
	partnerCtaSectionConfig,
	partnerTestimonialSectionConfig,
	partnerThirdCtaSectionConfig,
	programWithFilterSectionConfig,
	programWithToggleSectionConfig,
	socialPlatforms,
	testimonialSectionConfig,
	topCounsellorSectionConfig,
	universityWithFilterSectionConfig,
} from './constants';

// utils
import { levelAndType } from '@/utils/constants/program';
import getPlatform from '@/utils/getPlatform';
import {
	getBannerButton,
	getBannerImages,
	getBannerStats,
	getBannerSubTitle,
	getExamAcceptedScores,
	getProgramCardData,
	getProgramLevelFilterOptions,
	getProgramsByLevel,
	googleMapsURLToEmbedURL,
} from './util';

const isPlatformPartner = getPlatform() === 'partner';

export const getDetailBannerSection = (data) => {
	const titleImages = getBannerImages(data.images, data.name),
		subTitle = getBannerSubTitle(data),
		stats = getBannerStats(data),
		button = getBannerButton(data._id);

	let titleIcon = null;

	if (!isEmpty(data.images) && get(data, 'images.logo.original')) {
		titleIcon = {
			url: data.images.logo.original,
			alternativeText: data.name,
		};
	}

	return {
		...detailBannerSectionConfig,
		title: data.name,
		subTitle,
		titleIcon,
		titleImages,
		stats,
		button,
	};
};

export const getNavigationSection = () => {
	return { ...navigationSectionConfig };
};

export const getOverviewSection = (description) => {
	return { ...overviewSectionConfig, body: description };
};

export const getProgramListWithToggleSection = (
	data,
	programs,
	levelFilter = '',
	listCount = 6
) => {
	const universityLogo = get(data, 'images.logo.original');
	const programLevelFilterOptions = getProgramLevelFilterOptions(programs);
	const cardList = getProgramsByLevel(programs, levelFilter, listCount).map(
		(program) =>
			getProgramCardData(data._id, universityLogo, program, data.client_type)
	);
	return {
		...programWithToggleSectionConfig,
		cardList,
		programLevelFilterOptions,
		link: `${constants.seo.sitemapBaseUrl}/course-finder/universities/${data.slug}/program`,
		requireAuthBeforeRedirect: !isPlatformPartner,
	};
};

export const getCostTableSection = (costs, levelFilter) => {
	const costTableConfig = getCostTableConfig(levelFilter),
		currency = costs.currency || '';

	const rows = costTableConfig.rowOrder.reduce((rows, key) => {
		if (costs[key]) {
			rows.push([
				{ value: costTableConfig.rowHeadingLabels[key] },
				{ value: `${currency} ${costs[key]}` },
			]);
		}
		return rows;
	}, []);

	return { ...costTableConfig, rows };
};

export const getExamAcceptedTableSection = (
	eligibilityCriteriaDetails,
	levelFilter
) => {
	const examData =
		levelFilter === levelAndType['UG Degree/Bachelors'] &&
		!isEmpty(eligibilityCriteriaDetails.ug)
			? eligibilityCriteriaDetails.ug
			: eligibilityCriteriaDetails.pg;

	const scores = getExamAcceptedScores(examData);
	const rows = examAcceptedTableConfig.rowOrder.reduce((rows, key) => {
		if (scores[key]) {
			rows.push([
				{ value: examAcceptedTableConfig.rowHeadingLabels[key] },
				{ value: scores[key] },
			]);
		}
		return rows;
	}, []);

	return { ...examAcceptedTableConfig, rows };
};

export const getFeeEstimateTableSection = (
	universitySlug,
	programs,
	levelFilter
) => {
	const filteredPrograms = getProgramsByLevel(programs, levelFilter);

	const currency =
		(filteredPrograms[0] && filteredPrograms[0].costs.currency) || '';

	const rows = filteredPrograms.reduce((rows, program) => {
		rows.push([
			{ value: program.name },
			{ value: get(program, 'length', '-') },
			{ value: get(program, 'costs.tuition_fee', '-') },
		]);

		return rows;
	}, []);

	const feeEstimateTableConfig = getFeeEstimateTableConfig(currency);

	return {
		...feeEstimateTableConfig,
		rows,
		link: `${constants.seo.sitemapBaseUrl}/course-finder/universities/${universitySlug}/program`,
	};
};

export const getContactAndLocationSection = (
	universityName,
	universityLocation,
	contactAndSocialInfo = {}
) => {
	const mapEmbedUrl = googleMapsURLToEmbedURL(
		universityName,
		universityLocation
	);

	const socialIconActions = socialPlatforms.reduce((iconActions, platform) => {
		if (contactAndSocialInfo[platform]) {
			iconActions.push({
				platform,
				goto: contactAndSocialInfo[platform],
			});
		}

		return iconActions;
	}, []);

	const sectionData = {
		...contactAndLocationSectionConfig,
		mapEmbedUrl,
		socialIconActions,
	};

	if (contactAndSocialInfo.help_desk_email) {
		sectionData.contactEmail = contactAndSocialInfo.help_desk_email;
	}

	if (contactAndSocialInfo.phone_number) {
		sectionData.contactNumber = contactAndSocialInfo.phone_number;
	}

	return sectionData;
};

export const getBlogListingSection = (blogs) => ({
	...blogListingSectionConfig,
	cardList: blogs.map(({ attributes }) => ({
		...attributes,
		link: `/expert-tips/${attributes.slug}`,
		image: {
			url: get(attributes, 'image.data.attributes.url', ''),
			alternativeText: get(
				attributes,
				'image.data.attributes.alternativeText',
				''
			),
		},
	})),
});

export const getMetaData = (
	universityName,
	universityLogo = defaultCoverImage
) => ({
	keywords: {
		name: 'keywords',
		content: `${universityName}, Study abroad ${universityName}, study overseas ${universityName}, overseas study ${universityName}, best overseas education consultancy, study abroad consultancy`,
	},
	'twitter:card': {
		name: 'twitter:card',
		content: 'summary',
	},
	'og:title': {
		content: `Study Abroad at ${universityName}`,
		property: 'og:title',
	},
	'twitter:title': {
		content: `Study Abroad at ${universityName}`,
		name: 'twitter:title',
	},
	'og:image': {
		content: universityLogo,
		property: 'og:image',
	},
	'twitter:image': {
		content: universityLogo,
		name: 'og:image',
	},
	'og:description': {
		content: `Study Abroad at ${universityName} Rankings, Fees, Courses, Acceptance Rate, Intake, Eligibility, Scholarships for ${universityName} at Azent Overseas Education`,
		property: 'og:description',
	},
	'twitter:description': {
		content: `Study Abroad at ${universityName} Rankings, Fees, Courses, Acceptance Rate, Intake, Eligibility, Scholarships for ${universityName} at Azent Overseas Education`,
		name: 'og:description',
	},
});

export const getUniversityProgramPageMetaData = (
	universityName,
	universityLogo = defaultCoverImage
) => ({
	keywords: {
		name: 'keywords',
		content: `Top programs at ${universityName}`,
	},
	'twitter:card': {
		name: 'twitter:card',
		content: 'summary',
	},
	'og:title': {
		content: `Top programs at ${universityName}`,
		property: 'og:title',
	},
	'twitter:title': {
		content: `Top programs at ${universityName}`,
		name: 'twitter:title',
	},
	'og:image': {
		content: universityLogo,
		property: 'og:image',
	},
	'twitter:image': {
		content: universityLogo,
		name: 'og:image',
	},
	'og:description': {
		content: `Top programs at ${universityName}`,
		property: 'og:description',
	},
	'twitter:description': {
		content: `Top programs at ${universityName}`,
		name: 'og:description',
	},
});

export const getTopCounsellorSection = (universityCountry, data = []) => ({
	...topCounsellorSectionConfig,
	title: `Top Counsellors for ${universityCountry}`,
	labelOnNavigation: `Top Counsellors for ${universityCountry}`,
	cardList: data,
	link: `${constants.seo.sitemapBaseUrl}/counsellors?target_country=${universityCountry}`,
});

export const getProgramListWithFilterSection = (programs, data) => {
	const cardList = programs.map((program) =>
		getProgramCardData(
			data._id,
			get(data, 'images.logo.original'),
			program,
			data.client_type
		)
	);

	return {
		...programWithFilterSectionConfig,
		cardList,
		requireAuthBeforeRedirect: !isPlatformPartner,
	};
};

export const getIndexBannerSection = (title, highlightTitle) => ({
	...indexBannerSectionConfig,
	title,
	highlightTitle,
});

export const getUniversityWithFilterSection = (searchQueryValues) => ({
	...universityWithFilterSectionConfig,
	searchQueryValues,
});

export const getGeneralInformationSection = (details) => {
	const cardList = [];

	generalInformationDataKeys.forEach(({ title, key }) => {
		if (get(details, `${key}.description`))
			cardList.push({ title: title, body: details[key].description });
	});

	return { ...generalInformationSectionConfig, cardList };
};

export const getCtaSection = () =>
	isPlatformPartner
		? { ...partnerCtaSectionConfig }
		: { ...compassCtaSectionConfig };

export const getSecondCtaSection = () =>
	isPlatformPartner
		? { ...partnerCommissionCtaSectionConfig }
		: { ...appHighlightSectionConfig };

export const getThirdCtaSection = () =>
	isPlatformPartner
		? { ...partnerThirdCtaSectionConfig }
		: { ...modalFormCtaSectionConfig };

export const getExploreCourseCtaSection = () => ({
	...compassExploreCourseCtaSectionConfig,
});

export const getExpenseCalculatorCtaSection = () => ({
	...compassExpenseCalculatorCtaSectionConfig,
});

export const getTestimonialSection = () =>
	isPlatformPartner
		? { ...partnerTestimonialSectionConfig }
		: { ...testimonialSectionConfig };

export const deserializeInterestRegions = (data) =>
	(data || []).map((region) => {
		let label = region.city || '';
		if (region.state)
			label = label ? label + `, ${region.state}` : region.state;
		if (region.country)
			label = label ? label + `, ${region.country}` : region.country;
		return { ...region, label };
	});

export const getValidPrograms = (programs) => {
	if (isEmpty(programs)) return [];

	let filteredPrograms = [],
		currentProgram;

	for (let i = 0; i < programs.length; i++) {
		currentProgram = programs[i];

		if (
			!currentProgram.level_and_type ||
			(!get(currentProgram, 'costs.tuition_fee') &&
				!currentProgram.length &&
				isEmpty(currentProgram.intake_months))
		) {
			continue;
		}

		filteredPrograms.push(currentProgram);
	}

	return filteredPrograms;
};
