import { createSlice } from '@reduxjs/toolkit';

// constants
import constants from '@/constants';

// api
import { auth, getProfile } from '@/api';
import { setStudentData, setStudentLoading } from '@/store/actions';

// utils
import { removeToken } from '@/utils/auth';
import { checkPersistentData } from '@/utils/browserStorage/persistentData';
import { deserializeProfile } from '@/utils/deserializer/profile';
import { registeredEventsGetAndStore } from './event';

const initialState = {
	loading: true,
	data: {
		registered: false,
		registrationUserDetails: null,
	},
	error: null,
};

export const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setUserRegistered(state, { payload }) {
			state.data.registered = payload;
		},
		setUserData: (state, { payload }) => {
			Object.assign(state.data, payload);
		},
		resetUserData: (state) => {
			state.loading = false;
			state.data = initialState.data;
			state.error = null;
		},
	},
});

const { setUserData, setUserLoading } = user.actions;

const userGetAndStore = () => (dispatch) => {
	const authAccessToken = checkPersistentData(
		constants.cookie.authAccessToken,
		{ path: constants.cookie.path, domain: constants.cookie.domain }
	);
	if (authAccessToken) {
		dispatch(setUserLoading(true));
		Promise.all([auth(), getProfile(), dispatch(registeredEventsGetAndStore())])
			.then((data) => {
				if (data[0].success) {
					dispatch(setUserData(data[0].data));
					dispatch(setStudentData(deserializeProfile(data[1])));
				}
			})
			.finally(() => {
				dispatch(setUserLoading(false));
				dispatch(setStudentLoading(false));
			});
	} else {
		removeToken();
		dispatch(setUserLoading(false));
		dispatch(setStudentLoading(false));
	}
};

export default user;
export { userGetAndStore };
