import get from 'lodash/get';

// utils
import { removeToken, setToken } from '@/utils/auth';
import { getStudentInfoFromToken } from '@/utils/tokenParser';
import authInstance from './axios-instances/auth';

// constants
const defaultErrorMessage = 'Some error occurred, Please try again.';

const buildResponseData = (error, data) => {
	const result = { success: !error };
	if (data) result.data = data;
	if (error)
		result.errorMessage =
			get(error, 'response.data.error.message') || defaultErrorMessage;

	return result;
};

export async function sendOTP(data) {
	let result;

	await authInstance
		.post('/auth/login/request_otp', data)
		.then((res) => {
			if (get(res, 'data.success')) result = buildResponseData(null);
		})
		.catch((error) => {
			result = buildResponseData(error);
		});

	return result || {};
}

export const logIn = async (loginDetails) => {
	let result;

	await authInstance
		.post('/auth/login/otp', { ...loginDetails })
		.then((res) => {
			const accessToken =
				get(res, 'data.success') && get(res, 'data.data.access_token');

			if (accessToken) {
				result = buildResponseData(null, getStudentInfoFromToken(accessToken));
				setToken(accessToken);
			}
		})
		.catch((error) => {
			result = buildResponseData(error);
		});

	return result || {};
};

export const auth = async () => {
	let result;

	await authInstance
		.get('/auth')
		.then((res) => {
			if (get(res, 'data.data.user')) {
				result = buildResponseData(null, {
					userType: 'student',
					...res.data.data.user,
					registered: true,
				});
			}
		})
		.catch((error) => {
			result = buildResponseData(error);
			removeToken();
		});

	return result || {};
};
