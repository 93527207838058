import {
	removePersistentData,
	setPersistentData,
} from './browserStorage/persistentData';

// constants
import constants from '@/constants';

export const getOTPPayload = (
	username,
	isResend = false,
	authConstants,
	location
) => {
	let data = {
		username: !isNaN(username) ? `+91${username}` : username,
		resend: isResend,
	};
	if (isResend) return data;

	const searchObject = Object.fromEntries(new URLSearchParams(location.search));
	data = {
		...(authConstants || {}),
		...data,
		utm_params: { ...searchObject, href: location.href },
	};
	if (searchObject.city || searchObject.center) {
		data.city = searchObject.city || searchObject.center;
	}

	return data;
};

export const setToken = (accessToken) => {
	setPersistentData(constants.cookie.authAccessToken, accessToken, {
		path: constants.cookie.path,
		domain: constants.cookie.domain,
		maxAge: constants.cookie.maxAge,
		sameSite: 'none',
		secure: true,
	});
};

export const removeToken = () => {
	removePersistentData(constants.cookie.authAccessToken, {
		path: constants.cookie.path,
		domain: constants.cookie.domain,
	});
};
