import * as Sentry from '@sentry/node';
import { ConnectedRouter } from 'connected-next-router';
import get from 'lodash/get';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// mui
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

// components
import DeviceSetter from '@/components/widgets/device-setter';
import ErrorBoundary from '@/components/widgets/error-boundary';

// constants
import constants from '@/constants';
const Layout = dynamic(() => import('@/layouts'), { ssr: true });
// actions
import { wrapper } from '@/store';
import {
	resetUserModalData,
	setDevice,
	userGetAndStore,
} from '@/store/actions';

// utils
import * as eventActions from '@/utils/analytics';
import { checkCookieEnabled } from '@/utils/browserStorage/cookie';
import emotionCache from '@/utils/emotionCache';
import mobileDetect from '@/utils/is-mobile';
import { pageTitle } from '@/utils/meta';
import '@/utils/polyfills';
import theme from '@/utils/theme';

// styles
import '@/styles/styles.css';

const cookieEnabled = checkCookieEnabled();

if (constants.SENTRY_DSN && cookieEnabled) {
	Sentry.init({
		enabled: process.env.APP_ENV !== 'development',
		dsn: constants.SENTRY_DSN,
		environment: process.env.APP_ENV,
		ignoreErrors: ['IntersectionObserver is not defined'],
	});
}

const MyApp = function MyApp(props) {
	const { Component, pageProps, err, router } = props;
	const dispatch = useDispatch();
	const location = useSelector((state) => state.router.location);
	pageProps.resolvedUrl = router.asPath;

	React.useEffect(() => {
		eventActions.logPageView();
		dispatch(resetUserModalData());
	}, [location.pathname]);

	React.useEffect(() => {
		window.history.scrollRestoration = 'manual';
		dispatch(userGetAndStore());
	}, []);

	return (
		<CacheProvider value={emotionCache}>
			<Head>
				<title>{pageTitle(pageProps)}</title>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width"
				/>
				<link
					rel="canonical"
					href={
						get(pageProps, 'meta.canonical_url') ||
						constants.seo.sitemapBaseUrl + router.asPath.split('?')[0]
					}
				/>
			</Head>
			{pageProps.paymentDetail && (
				<Script
					src="https://checkout.razorpay.com/v1/checkout.js"
					strategy="lazyOnload"
				></Script>
			)}
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ConnectedRouter>
					<ErrorBoundary>
						{Component.getLayout ? (
							Component.getLayout(
								<>
									<Component {...pageProps} err={err} />
									<DeviceSetter />
								</>,
								pageProps
							)
						) : (
							<Layout
								showBackgroundImage={pageProps.showBackgroundImage}
								resolvedUrl={pageProps.asPath}
							>
								<Component {...pageProps} err={err} />
								<DeviceSetter />
							</Layout>
						)}
					</ErrorBoundary>
				</ConnectedRouter>
			</ThemeProvider>
		</CacheProvider>
	);
};

MyApp.propTypes = {
	Component: PropTypes.elementType.isRequired,
	pageProps: PropTypes.object,
	emotionCache: PropTypes.object,
};

MyApp.defaultProps = {
	pageProps: {},
};

MyApp.getInitialProps = wrapper.getInitialAppProps(
	(store) => async (appContext) => {
		const req = appContext.ctx.req;

		const userAgent = get(req, 'headers.user-agent') || '',
			_mobileDetect = mobileDetect(userAgent),
			isGooglebot = _mobileDetect.isGooglebot(),
			_isMobile =
				!isGooglebot &&
				(
					get(req, 'headers.cloudfront-is-mobile-viewer') ||
					get(req, 'CloudFront-Is-Mobile-Viewer') ||
					_mobileDetect.isMobile() ||
					''
				).toString() === 'true',
			_isTablet =
				(
					get(req, 'headers.cloudfront-is-tablet-viewer') ||
					get(req, 'headers.CloudFront-Is-Tablet-Viewer') ||
					''
				).toString() === 'true';

		store.dispatch(
			setDevice({
				isMobile: _isMobile && !_isTablet,
				isDesktop: !_isMobile && !_isTablet,
				isTablet: _isTablet,
			})
		);
		return await App.getInitialProps(appContext);
	}
);

export default wrapper.withRedux(MyApp);
